<template>
  <html>
    <body class="" style="background: #F8F8F8">
      <div class="h-screen grid grid-cols-6 grid-flow-col">
        <MenuLateral/>
        <div class="col-span-5" style="background:#F8F8F8;">
          <Header/>
          <SolicitudIngreso/>
          <Footer/>
        </div>
      </div>
    </body>
  </html>
</template>

<script>
import Header from "@/components/Analyst/Header.vue"
import SolicitudIngreso from "@/components/Analyst/Admin/SolicitudesIngreso.vue"
import MenuLateral from '@/components/Analyst/MenuLateral.vue'
import Footer from '@/components/Analyst/Footer.vue'
export default {
  name: 'Principal',
  components: {
    Header,
    SolicitudIngreso,
    MenuLateral,
    Footer
  }
}
</script>

<style scoped>
.center {
  padding: 00px 0;
  border: 3px solid #ffffff;
  text-align: center;
}
@media (min-width: 766px) {
  .collapse.dont-collapse-sm {
    display: block;
    height: auto !important;
    visibility: visible;
  }
}
#button_popups{
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  background-color: #FEB72B;
  width: 150px;
  height: 30px;
  padding-top: 0%;
}
button {outline: none !important;}
</style>